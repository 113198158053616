@import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800');

/******************************
        General Styling
******************************/

* {
  box-sizing: border-box;
}
body {
  background-image: url('https://i.ytimg.com/vi/k_iNtdO-GdY/maxresdefault.jpg') !important;
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: 'Fira Sans', sans-serif;
}

h1,h2,h3,h4,h5,h6,label,span {
  font-weight: 500;
  font-family: 'Fira Sans', sans-serif;
}

body, html, .App, #root, .wrapper {
  width: 100%;
  height: 100%;
}

.wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

/******************************
      Home Page Styling
******************************/

.btn-home {
  width: 200px;
}

.text-home-primary {
  color: #40E0D0;
}

.text-home-secondary {
  color: #fff;
}

/******************************
        Navbar Styling
******************************/

.nav-dropdown {
  background-color: #000 !important;
  color: #000;
  border-radius: 0;
}

.navbar-img {
  max-height: 50px;
  max-width: 40px;
}

.navbar-dark {
  background-color: #000;
  height: 8%;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.navbar-nav {
  color: rgba(255,255,255,1);
}

.nav-text {
  color: #fff !important;
}

.nav-text:hover {
  color: #40E0D0 !important;
}

.nav-selected {
  text-decoration: underline !important;
  color: #40E0D0 !important;
}

.link {
  color: #40E0D0 !important;
}

/******************************
  Login/Signup Pages Styling
******************************/

.auth-inner {
  width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all .3s;
  overflow-wrap: break-word;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}

/******************************
    Dashboard Page Styling
******************************/

.dashboard-inner {
  height: 80%;
  width: 95% !important;
}

.dashboard-card {
  height: 93%;
}

.dashboard-body {
  max-height: 300px;
  overflow: scroll;
}

.temp1 {
  width: 50%;
}

.temp7 {
  width: 25rem;
}

/******************************
    Tasks/Users Pages Styling
******************************/

.content-inner {
  height: 600px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all .3s;
}

@import url(https://fonts.googleapis.com/css?family=Open+Sans);

body {
  background: #f2f2f2;
  font-family: 'Open Sans', sans-serif;
}

.search {
  width: 30%;
  float: left;
  position: relative;
  display: flex;
}

.searchTerm {
  width: 100%;
  border: 3px solid #ddd;
  border-right: none;
  padding: 5px;
  height: 36px;
  border-radius: 5px 0 0 5px;
  outline: none;
  color: #000;
}

.searchTerm:focus {
  border: 3px solid #40E0D0;
}

.searchTerm:focus ~ .searchButton {
  border: 1px solid #40E0D0;
  background: #40E0D0;
}

.searchButton {
  width: 40px;
  height: 36px;
  border: 1px solid #000;
  background: #000;
  text-align: center;
  color: #fff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 20px;
}

.sort-task {
  float: right;
}

.Low {
  background-color: #28A745;
  font-weight: bold;
}

.Medium {
  background-color: #FFC107;
  font-weight: bold;
}

.High {
  background-color: #DC3545;
  font-weight: bold;
}

.Completed {
  background-color: #17A2B8;
  font-weight: bold;
}

.Overdue {
  background-color: #6C757D;
  font-weight: bold;
}

.wrap {
  width: 30%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/******************************
    Profile Pages Styling
******************************/

.profile-inner {
  height: 630px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all .3s;
  overflow: scroll;
  overflow-wrap: break-word;
}

/******************************
  Modal Layout/Content Styling
******************************/

.modal_header_bg {
  background-color: #40E0D0;
  overflow: hidden;
}

.prompt {
  text-align: center;
}

.trackedcontent {
  font-weight: bold;
  color: red;
}

.load {
  align-self: center;
}

.large-modal {
  width: 80%;
  max-width:80%!important;
}

.details-inner {
  overflow-wrap: break-word;
}

/******************************
        Table Styling
******************************/

#title {
  text-align: center;
  font-family: arial, sans-serif;
}

#task-table {
  text-align: center;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  border: 3px solid #ddd;
  width: 100%;
}

#task-table td, #task-table th {
  border: 1px solid #ddd;
  padding: 8px;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#task-table tr:nth-child(even){
  background-color: #f2f2f2;
}

#task-table tr:hover {background-color: #ddd;}

#task-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #000;
  color: white;
}

#user-table {
  text-align: center;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  border: 3px solid #ddd;
  width: 100%;
}

#user-table td, #user-table th {
  border: 1px solid #ddd;
  padding: 8px;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#user-table tr:nth-child(even){
  background-color: #f2f2f2;
}

#user-table tr:hover {background-color: #ddd;}

#user-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #000;
  color: white;
}

.tabledisplay {
  padding-top: 10px;
  height: 100%;
  width: 100%;
  height: 350px;
  overflow: scroll;
}

.Yes {
  background-color: #28A745;
  font-weight: bold;
}

.No {
  background-color: #DC3545;
  font-weight: bold;
}

/******************************
             Others
******************************/

.icon-36 {
  font-size: 36px;
}

.icon-32 {
  font-size: 32px;
}

.telegram-color {
  color: #0088CC;
}

.chatbotstyle {
  position: absolute;
  bottom: 0px;
}